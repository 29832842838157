import React from "react";
// nodejs library that concatenates classes
// import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
// import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody.js";
// import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

// import team1 from "assets/img/faces/avatar.jpg";
// import team2 from "assets/img/faces/christian.jpg";
// import team3 from "assets/img/faces/kendall.jpg";

const useStyles = makeStyles(styles);

export default function TeamSection() {
  const classes = useStyles();
  // const imageClasses = classNames(
  //   classes.imgRaised,
  //   classes.imgRoundedCircle,
  //   classes.imgFluid
  // );
  return (
    <div className={classes.section}>
      <h2 className={classes.title}>Contact Us Now</h2>
      <div>
          <GridItem xs={12} sm={12} md={12}>
            <Card plain>

              <h4 className={classes.cardTitle}>
                CALL: {' '} 
               
                <large className={classes.smallTitle}>(347) 639-0311</large>
                
              </h4>

              <h4 className={classes.cardTitle}>
                TEXT: {' '} 
               
                <large className={classes.smallTitle}>(347) 658-6046</large>
              </h4>
              <h4 className={classes.cardTitle}>
    HOURS: {' '} 
               
               <large className={classes.smallTitle}>7:30 a.m - 9:00 p.m (MON-SUN)</large>
      </h4>
      <h4 className={classes.cardTitle}>
                LOCATION: {' '} 
               
                <large className={classes.smallTitle}>8704 Astoria Blvd</large>
                <large className={classes.smallTitle}>East Elmhurst, NY 11369</large>
              </h4>

            </Card>
          </GridItem>
     
      </div>
    </div>
  );
}
