import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

import LocalLaundryServiceIcon from '@material-ui/icons/LocalLaundryService';
import ApartmentIcon from '@material-ui/icons/Apartment';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>87 Laundry</h2>
          <h4 className={classes.description}>
          We provide full service of laundry and dry clean, also FREE pickup and delivery most of Queens area.Price Only $1.09 per lb. 
          New Customer get 20% off!! 
          </h4>
          <h4  className={classes.description} >Commercial/Wholesale Free wash 100 lb.</h4>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={6} sm={6} md={3}>
            <InfoArea
              title="Call or Text us"
              icon={WhatsAppIcon}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={6} sm={6} md={3}>
            <InfoArea
              title="Door to Door Pick Up"
              icon={ApartmentIcon}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={6} sm={6} md={3}>
            <InfoArea
              title="Wash and Fold"
              icon={LocalLaundryServiceIcon}
              iconColor="warning"
              vertical
            />
          </GridItem>
          <GridItem xs={6} sm={6} md={3}>
            <InfoArea
              title="Fast Delivery"
              icon={LocalShippingIcon}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
