import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
// import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
// import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import ProductSection from "./Sections/ProductSection.js";
import TeamSection from "./Sections/TeamSection.js";
import CarouselSection from "./Sections/CarouselSection.js";
import ReactGA from 'react-ga';

// const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  // const { ...rest } = props;
  ReactGA.initialize('UA-148798108-3');
  ReactGA.pageview(window.location.pathname + window.location.search);
    /*let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();*/
  return (
   


    <div>
      {/* <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="87 Laundry"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      /> */}
      
      <Parallax filter image={require("assets/img/landing-bg.jpg")}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title}>87 ASTORIA BLVD. LAUNDRY</h1>
              <h2>
                FREE Pick and Delivery 
              </h2>
              <h3>
               $1.09 per/lb, first 9lb for $12
              </h3>
              <h3>
              New Customer get 20% off!!
              </h3>
              <br />
              <Button
                color="danger"
                size="lg"
                href="tel:+13476390311"
                target="_blank"
                rel="noopener noreferrer"
                onclick = {
                  ReactGA.pageview(window.location.pathname + window.location.search+ "/phone call clicked")
                }
              >
                <i className="fas fa-phone" />
                Now Call (347) 639-0311
              </Button>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <ProductSection />
          <TeamSection />
          <CarouselSection />
        </div>

      </div>
      
      <Footer />
    </div>
  );
}
